import { defineStore } from 'pinia'

import Api from '../api/Api.js'

export const AuthStore = defineStore('AuthStore', {
  state: () => ({
    redirectUrl: window.localStorage.redirectUrl || null,
    user: window.localStorage.user ? JSON.parse(window.localStorage.user) : null,
    token: window.localStorage.AuthToken ? window.localStorage.AuthToken : null
  }),

  getters: {
    getRedirectUrl(state) {
      return state.redirectUrl;
    },
    getUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return Boolean(state.token);
    },

  },

  actions: {
    async authorize(loginParams) {
      const { data } = await Api.UserCalls.authorize(loginParams)

      this.user = data.user
      this.token = data.token
      window.localStorage.AuthToken = data.token

      return data
    },
    setRedirectUrl(url) {
      if (!url) {
        this.redirectUrl = null;
        delete window.localStorage.redirectUrl;
        return;
      }

      this.redirectUrl = url;
      window.localStorage.redirectUrl = url;
    },
    setAuthorizedUser(user) {
      if (!user) {
        this.user = null;
        delete window.localStorage.user;
        return;
      }

      this.user = user;
      window.localStorage.user = JSON.stringify(user);
    }

  },
})

export default AuthStore;