import axios from 'axios'

import UserCalls from './modules/UserCalls.js'

export const client = axios.create({
  baseURL: /*import.meta.env.VITE_BACKGEND_URL + */'/api/',
  timeout: 30000,
  // headers: {
  //   'api-key': apiKey,
  //   'Content-Type': 'application/json; charset=utf-8',
  // },
  transformResponse: [function (data) {
    if (typeof data === 'string') {
      data = JSON.parse(data)
    }

    // console.log("BLAK", data);
    // console.log("Data is ", typeof data)

    // if (data && data.data && data.data.data) {
    //   return data.data.data
    // }

    if (data.data) {
      return data.data
    }

    return data
  }]
})

export const Api = {
  UserCalls
}

export default Api